var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"title"},[_c('p',[_vm._v("管理用戶")])]),_c('div',{staticClass:"d-flex mb-2"},[_c('b-form-input',{staticClass:"search-product",staticStyle:{"width":"400px"},attrs:{"placeholder":"Please enter user name/ company name/ Email"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-button',{staticClass:"mr-4 ml-2",attrs:{"variant":"primary"},on:{"click":_vm.getUsers}},[_vm._v(" Search ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-creat-user-type",modifiers:{"modal-creat-user-type":true}}],staticClass:"ml-4",attrs:{"size":"sm","variant":"outline-primary"}},[_vm._v(" ADD USER + ")])],1),_c('el-select',{staticStyle:{"width":"400px"},attrs:{"placeholder":"A/C Status"},model:{value:(_vm.selectStatus),callback:function ($$v) {_vm.selectStatus=$$v},expression:"selectStatus"}},_vm._l((_vm.optionStatus),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.text,"value":item.value}})}),1),_c('div',[_c('br'),_c('el-tabs',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"type":"card"},on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"Admin System User","name":"admin"}},[_c('b-table',{attrs:{"bordered":"","id":"admin-table","striped":"","hover":"","responsive":"","items":_vm.adminData,"fields":_vm.$store.state.highlightCol.firTable
                ? _vm.$store.state.newTableFields
                : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
                $event,
                _vm.tableFields,
                _vm.$store.state.newTableFields,
                'firTable'
              )}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('p',{staticStyle:{"color":"#0e72bc","text-decoration":"underline"},on:{"click":function($event){return _vm.goDetail(row.item.id, 'view', 'Admin')}}},[_vm._v(" "+_vm._s(row.item.name)+" ")])]}},{key:"cell(due_date)",fn:function(row){return [_c('div',{staticClass:"text-nowrap"},[(row.item.due_date === '0000-00-00')?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(_vm.$moment(row.item.due_date).format()))])])]}},{key:"cell(last)",fn:function(row){return [_c('div',{staticClass:"text-nowrap"},[(row.item.last)?_c('span',[_vm._v(_vm._s(_vm.$moment(row.item.last).format("DD-MM-YYYY HH:mm:ss")))]):_c('span',[_vm._v("-")])])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",staticStyle:{"width":"84px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(row.item.id, 'edit', 'Admin')}}},[_vm._v(" 作出修訂 ")]),_c('b-button',{staticStyle:{"width":"64px"},attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.delUser(row.item.id)}}},[_vm._v(" 刪除 ")])],1)]}}])})],1),_c('el-tab-pane',{attrs:{"label":"Report User","name":"normal"}},[_c('b-table',{attrs:{"bordered":"","id":"user-table","striped":"","hover":"","responsive":"","items":_vm.userData,"fields":_vm.$store.state.highlightCol.secTable
                ? _vm.$store.state.newSecTableFields
                : _vm.tableFields},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
                $event,
                _vm.tableFields,
                _vm.$store.state.newSecTableFields,
                'secTable'
              )}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.goDetail(row.item.id, 'view', 'Pro/Normal')}}},[_vm._v(" "+_vm._s(row.item.name)+" ")])]}},{key:"cell(due_date)",fn:function(row){return [_c('div',{staticClass:"text-nowrap"},[(row.item.due_date === '0000-00-00')?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(_vm.$moment(row.item.due_date).format()))])])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",staticStyle:{"width":"90px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.goDetail(row.item.id, 'edit', 'Pro/Normal')}}},[_vm._v(" 作出修訂 ")]),_c('b-button',{staticStyle:{"width":"60px"},attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.delUser(row.item.id)}}},[_vm._v(" 刪除 ")])],1)]}}])})],1)],1)],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","id":"modal-creat-user-type","title":"Select User Type"}},[_c('div',{staticClass:"d-flex justify-content-center my-4"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goDetail(null, 'new', 'Admin')}}},[_c('b',[_vm._v("Admin System User")])]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.goDetail(null, 'new', 'Pro/Normal')}}},[_c('b',[_vm._v("Report User")])])],1)]),_c('div',{staticClass:"float-right"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.listParams.page,"page-sizes":[10, 20, 30],"page-size":_vm.listParams.count,"layout":"total, sizes, prev, pager, next","total":_vm.listParams.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }