<template>
  <div>
    <b-card>
      <div class="title">
        <p>管理用戶</p>
      </div>
      <div class="d-flex mb-2">
        <b-form-input
          style="width: 400px"
          v-model="search"
          placeholder="Please enter user name/ company name/ Email"
          class="search-product"
        />
        <b-button @click="getUsers" class="mr-4 ml-2" variant="primary">
          Search
        </b-button>
        <b-button
          size="sm"
          class="ml-4"
          variant="outline-primary"
          v-b-modal.modal-creat-user-type
        >
          ADD USER +
        </b-button>
      </div>
      <el-select
        style="width: 400px"
        v-model="selectStatus"
        placeholder="A/C Status"
      >
        <el-option
          v-for="(item, index) in optionStatus"
          :key="index"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div>
        <br />
        <el-tabs
          v-loading="tableLoading"
          v-model="activeName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane label="Admin System User" name="admin">
            <b-table
              bordered
              id="admin-table"
              striped
              hover
              responsive
              :items="adminData"
              :fields="
                $store.state.highlightCol.firTable
                  ? $store.state.newTableFields
                  : tableFields
              "
              @head-clicked="
                commonHighLightCol(
                  $event,
                  tableFields,
                  $store.state.newTableFields,
                  'firTable'
                )
              "
            >
              <template v-slot:cell(name)="row">
                <p
                  style="color: #0e72bc; text-decoration: underline"
                  @click="goDetail(row.item.id, 'view', 'Admin')"
                >
                  {{ row.item.name }}
                </p>
              </template>
              <template v-slot:cell(due_date)="row">
                <div class="text-nowrap">
                  <span v-if="row.item.due_date === '0000-00-00'">-</span>
                  <span v-else>{{ $moment(row.item.due_date).format() }}</span>
                </div>
              </template>
              <template v-slot:cell(last)="row">
                <div class="text-nowrap">
                  <span v-if="row.item.last">{{
                    $moment(row.item.last).format("DD-MM-YYYY HH:mm:ss")
                  }}</span>
                  <span v-else>-</span>
                </div>
              </template>
              <template v-slot:cell(action)="row">
                <div class="d-flex">
                  <b-button
                    style="width: 84px"
                    size="sm"
                    class="mr-1"
                    @click="goDetail(row.item.id, 'edit', 'Admin')"
                  >
                    作出修訂
                  </b-button>
                  <b-button
                    style="width: 64px"
                    variant="outline-danger"
                    size="sm"
                    class=""
                    @click="delUser(row.item.id)"
                  >
                    刪除
                  </b-button>
                </div>
              </template>
            </b-table>
          </el-tab-pane>
          <el-tab-pane label="Report User" name="normal">
            <b-table
              bordered
              id="user-table"
              striped
              hover
              responsive
              :items="userData"
              :fields="
                $store.state.highlightCol.secTable
                  ? $store.state.newSecTableFields
                  : tableFields
              "
              @head-clicked="
                commonHighLightCol(
                  $event,
                  tableFields,
                  $store.state.newSecTableFields,
                  'secTable'
                )
              "
            >
              <template v-slot:cell(name)="row">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="goDetail(row.item.id, 'view', 'Pro/Normal')"
                >
                  {{ row.item.name }}
                </b-button>
              </template>
              <template v-slot:cell(due_date)="row">
                <div class="text-nowrap">
                  <span v-if="row.item.due_date === '0000-00-00'">-</span>
                  <span v-else>{{ $moment(row.item.due_date).format() }}</span>
                </div>
              </template>
              <template v-slot:cell(action)="row">
                <div class="d-flex">
                  <b-button
                    size="sm"
                    class="mr-1"
                    style="width: 90px"
                    @click="goDetail(row.item.id, 'edit', 'Pro/Normal')"
                  >
                    作出修訂
                  </b-button>
                  <b-button
                    style="width: 60px"
                    variant="outline-danger"
                    size="sm"
                    class=""
                    @click="delUser(row.item.id)"
                  >
                    刪除
                  </b-button>
                </div>
              </template>
            </b-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <b-modal
        centered
        hide-footer
        id="modal-creat-user-type"
        title="Select User Type"
      >
        <div class="d-flex justify-content-center my-4">
          <b-button
            size="sm"
            variant="primary"
            @click="goDetail(null, 'new', 'Admin')"
            class="mr-1"
          >
            <b>Admin System User</b>
          </b-button>
          <b-button
            @click="goDetail(null, 'new', 'Pro/Normal')"
            variant="primary"
            size="sm"
          >
            <b>Report User</b>
          </b-button>
        </div>
      </b-modal>
      <div class="float-right">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listParams.page"
          :page-sizes="[10, 20, 30]"
          :page-size="listParams.count"
          layout="total, sizes, prev, pager, next"
          :total="listParams.total"
        >
        </el-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getUsers, deleteUser } from "@/api/user.js"

export default {
  components: {},
  data() {
    return {
      tableLoading: false,
      search: "",
      perPage: 1,
      activeName: "admin",
      currentPage: 10,
      selectStatus: "",
      optionStatus: [
        { text: "快將過期", value: "Green" },
        { text: "已過期", value: "Pink" },
      ],
      tableData: [],
      presentData: [],
      tableFields: [
        { key: "type", label: "使用者類別" },
        { key: "email", label: "Email" },
        { key: "name", label: "使用者名稱" },
        // { key: 'e_email', label: 'E-Mail' },
        { key: "id", label: "Login ID" },
        { key: "company", label: "Company" },
        { key: "status", label: "A/C Status" },
        { key: "due_date", label: "A/C Due Date" },
        { key: "last", label: "Last online time" },
        { key: "action", label: "" },
      ],
      listParams: {
        page: 1,
        count: 10,
        total: "",
        type: "",
      },
      status: "",
      adminData: [],
      userData: [],
    }
  },
  computed: {
    rows() {
      return this.presentData.length
    },
  },
  watch: {
    activeName: {
      handler(val) {
        if (val) {
          this.listParams.page = 1
          this.getUsers()
        }
      },
      deep: true,
    },
  },
  methods: {
    handleSizeChange(val) {
      this.listParams.count = val
      this.getUsers()
    },
    handleCurrentChange(val) {
      this.listParams.page = val
      this.getUsers()
    },
    delUser(id) {
      deleteUser(id).then((res) => {
        this.getUsers()
      })
    },
    getUsers() {
      this.tableLoading = true
      let isType = ""
      if (this.activeName === "admin") {
        isType = "system"
      } else {
        isType = "user"
      }
      const data = {
        page: this.listParams.page,
        count: this.listParams.count,
        search: this.search,
        status: this.selectStatus,
        type: isType,
      }
      getUsers(data).then((res) => {
        console.log(res)
        const adminList = []
        const userList = []
        this.listParams.total = res.data.total
        if (res.data.system && res.data.system.length > 0) {
          res.data.system.map((el) => {
            adminList.push({
              type: el.role,
              email: el.info && el.info.email ? el.info.email : "-",
              name: el.name,
              e_email: el.info && el.info.email ? el.info.email : "-",
              id: el.id,
              company: el.info && el.info.company ? el.info.company : "-",
              status: el.status,
              due_date: el.expire_date,
              last: el.last_used_date,
            })
          })
        }
        if (res.data.user && res.data.user.length > 0) {
          res.data.user.map((el) => {
            userList.push({
              type: el.role,
              email: el.info && el.info.email ? el.info.email : "-",
              name: el.name,
              e_email: el.info && el.info.email ? el.info.email : "-",
              id: el.id,
              company: el.info && el.info.company ? el.info.company : "-",
              status: el.status,
              due_date: el.expire_date,
              last: el.last_used_date,
            })
          })
        }
        this.adminData = adminList
        this.userData = userList
        this.tableLoading = false
      })
    },
    goDetail(id, review, userType) {
      const query = {
        id: id || "",
        review,
        user_type: userType || "",
      }
      this.$router.push({ path: "/dashboard/user-info", query })
    },
    handleTabClick() {
      if (this.activeName === "admin") {
        this.presentData = this.tableData.filter((user) =>
          user.type.includes("Admin")
        )
      } else {
        this.presentData = this.tableData.filter(
          (user) => !user.type.includes("Admin")
        )
      }
    },
  },
  created() {
    this.getUsers()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
